import { FunctionComponent, ReactElement } from "react";
import { useStyletron } from "baseui";
import { Grid, Cell } from "baseui/layout-grid";


import { HeadingLarge, ParagraphMedium } from "baseui/typography";


interface LandingPageProps {
  children: ReactElement;
  gapTop?: number;
}

export const MidaliaApps: FunctionComponent = () => {
  return (
    <>
        <Outer>
          <Grid>
            <Cell span={[4, 8, 16, 24]}>
              <Inner gapTop={50}>
                <HeadingLarge style={{ fontFamily: "secular", color: "white" }}>
                  Midalia Apps!
                </HeadingLarge>
              </Inner>
              <Inner>
                <ParagraphMedium
                  style={{
                    margin: "0px",
                    fontFamily: "secular",
                    color: "white",
                  }}
                >
                  We going to implement mobile applications, stay tuned!
                </ParagraphMedium>
              </Inner>
            </Cell>
          </Grid>
        </Outer>
    </>
  );
};

const Outer: React.FunctionComponent<LandingPageProps> = ({ children }) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        background: "navy",
        height: `${window.outerHeight}px`,
      })}
    >
      {children}
    </div>
  );
};
const Inner: React.FunctionComponent<LandingPageProps> = ({
  children,
  gapTop,
}) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        //   background: theme.colors.accent200,
        color: theme.colors.accent700,
        padding: ".25rem",
        marginTop: `${gapTop ? gapTop : 0}px`,
        textAlign: "center",
      })}
    >
      {children}
    </div>
  );
};
