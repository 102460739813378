import { createContext, useContext, FC } from "react";
import { Route, Routes } from "react-router-dom";

import { MidaliaApps } from "./modules/MidaliaApps";
// import { LandingPage } from "./modules/LandingPage/LandingPage";
import { TermsOfService } from "./modules/LandingPage/TermsOfService";
import { PrivacyPolicy } from "./modules/LandingPage/PrivacyPolicy";
// import { NotFoundPage } from "./modules/LandingPage/NotFoundPage";
// import { Login } from "./modules/Login";
// import { Register } from "./modules/Register";
// import { Home } from "./modules/Home";
// import { CreateGoal } from "./modules/Goal";
import { Header } from "./modules/shared/Header";
import { Footer } from "./modules/shared/Footer";
import { useProvideAuth } from "./modules/Auth";
import { CookieNotice } from "react-cookienotice";
import 'react-cookienotice/dist/style.css'
import "./App.css";

type Props = {
  children?: React.ReactNode;
};

const defaultValues = {
  user: {
    accessToken: undefined,
    email: undefined,
    name: undefined,
    id: undefined,
  },
  signin: async (email: string, password: string) => false,
  signout: () => {},
};

const handleCookieAccepted = async () => {
  localStorage.setItem("cookie-accepted", "true");
  localStorage.setItem("cookie-accepted-at", String(new Date()));
};

const handleCookieRejected = async () => {
  localStorage.setItem("cookie-accepted", "false");
};

const authContext = createContext(defaultValues);

const ProvideAuth: FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

function App() {
  return (
    <ProvideAuth>
      <Header />
      <Routes>
        <Route path="/" element={<MidaliaApps />} />
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        {/* <Route path="/app" element={<Home />} />;
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/createGoal" element={<CreateGoal />}></Route>
        <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
      <Footer copyright="© Midalia" />
      <CookieNotice
        onAcceptButtonClick={handleCookieAccepted}
        onDeclineAllButtonClick={handleCookieRejected}
      />
    </ProvideAuth>
  );
}

export default App;
