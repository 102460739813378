import { FunctionComponent, ReactElement } from "react";
import { useStyletron } from "baseui";
import { Grid, Cell } from "baseui/layout-grid";

import { HeadingMedium, ParagraphMedium } from "baseui/typography";

interface LandingPageProps {
  children: ReactElement;
  gapTop?: number;
}

const pStyle = {};

export const PrivacyPolicy: FunctionComponent = () => {
  return (
    <>
      <Outer>
        <Grid>
          <Cell skip={1} span={[4, 8, 12]}>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Data Policy
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                We are committed to respecting your privacy when processing
                personal data. We observe all applicable legal provisions when
                processing personal data that is collected during visits to our
                website. Our website may contain links to the sites of other
                providers and integrated services (see below) which are not
                covered by this privacy statement.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Collection and processing of personal data
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                No personalized logs are kept for visitors to this website.
                However, data is recorded and exchanged whenever a website is
                visited. The Recognition in MIDALTRACKER the data which your
                browser sends to our server. This includes: Type and version of
                your web browser Operating system used Web page loaded
                Previously visited page (referrer URL) Time of server request
                The Recognition in MIDALTRACKER uses this data for statistical
                purposes only in order to improve the website. Data for our web
                statistics is collected anonymously (with shortened IP address)
                and cannot be traced to a specific individual. The data is not
                merged with other data sources.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Cookies
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                We use cookies to optimize display. Personal data is stored only
                in those cases where the user provides it, for example, when
                registering. A state-of-the-art encryption tool (Secure Sockets
                Layer, SSL) is used when transmitting personal data.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Option
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                If you no longer want us have your personal data, you can let us
                know any time so that we can block it accordingly.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Right to information
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                You are entitled to obtain information about your personal data
                stored with us, its origin and the purpose of storage at any
                time. Upon request, we will inform you in writing in accordance
                with applicable law whether and what personal information about
                you we have on record. In the event that the information we have
                contains errors despite all our efforts to be accurate, we will
                gladly correct it upon request. Information about the stored
                data can be obtained from the editors.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Disclosure of personal data and use for specified purpose
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                We will not use your personal data for any purposes other than
                those specified on collection. Your data will not be disclosed
                to third parties unless you have declared your express consent.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Data security
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                We employ technical and organizational security measures to
                protect your data against accidental or deliberate manipulation,
                loss, destruction and unauthorized access. We continuously
                improve our security arrangements to reflect technological
                advances.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Privacy policy
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                We employ technical and organizational security measures to
                protect your data against accidental or deliberate manipulation,
                loss, destruction and unauthorized access. We continuously
                improve our security arrangements to reflect technological
                advances.
              </ParagraphMedium>
            </Inner>
          </Cell>
        </Grid>
      </Outer>
    </>
  );
};

const Outer: React.FunctionComponent<LandingPageProps> = ({ children }) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        background: "peachpuff",
        height: `${window.innerHeight * 1.7}px`,
      })}
    >
      {children}
    </div>
  );
};
const Inner: React.FunctionComponent<LandingPageProps> = ({
  children,
  gapTop,
}) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        //   background: theme.colors.accent200,
        color: theme.colors.accent700,
        padding: ".25rem",
        marginTop: `${gapTop ? gapTop : 0}px`,
        textAlign: "center",
      })}
    >
      {children}
    </div>
  );
};
