import { FunctionComponent, ReactElement } from "react";
import { useStyletron } from "baseui";
import { Grid, Cell } from "baseui/layout-grid";

import { HeadingMedium, ParagraphMedium } from "baseui/typography";

interface LandingPageProps {
  children: ReactElement;
  gapTop?: number;
}

export const TermsOfService: FunctionComponent = () => {
  return (
    <>
      <Outer>
        <Grid>
          <Cell skip={1} span={[4, 8, 12]}>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Terms of Service
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                Please read these terms of service ("terms", "terms of service")
                carefully before using [website] website (the "service")
                operated by MIDALTRACKER ("us", 'we", "our").
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Conditions of Use
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                We will provide their services to you, which are subject to the
                conditions stated below in this document. Every time you visit
                this website, use its services or make a purchase, you accept
                the following conditions. This is why we urge you to read them
                carefully.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Privacy Policy
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                Before you continue using our website we advise you to read our
                privacy policy [link to privacy policy] regarding our user data
                collection. It will help you better understand our practices.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Copyright
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                Content published on this website is the protected by
                international copyright laws.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Communications
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                The entire communication with us is electronic. Every time you
                send us an email or visit our website, you are going to be
                communicating with us. You hereby consent to receive
                communications from us. If you subscribe to the news on our
                website, you are going to receive regular emails from us. We
                will continue to communicate with you by posting news and
                notices on our website and by sending you emails. You also agree
                that all notices, disclosures, agreements and other
                communications we provide to you electronically meet the legal
                requirements that such communications be in writing.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Applicable Law
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                By visiting this website, you agree that the laws of the [your
                location], without regard to principles of conflict laws, will
                govern these terms of service, or any dispute of any sort that
                might come between MIDALTRACKER and you, or its business
                partners and associates.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Disputes
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                Any dispute related in any way to your visit to this website or
                to products you purchase from us shall be arbitrated by state or
                federal court [your location] and you consent to exclusive
                jurisdiction and venue of such courts.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                Comments, Reviews, and Emails
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                Visitors may post content as long as it is not obscene, illegal,
                defamatory, threatening, infringing of intellectual property
                rights, invasive of privacy or injurious in any other way to
                third parties. Content has to be free of software viruses,
                political campaign, and commercial solicitation. We reserve all
                rights (but not the obligation) to remove and/or edit such
                content. When you post your content, you grant MIDALTRACKER
                non-exclusive, royalty-free and irrevocable right to use,
                reproduce, publish, modify such content throughout the world in
                any media.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                License and Site Access
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                We grant you a limited license to access and make personal use
                of this website. You are not allowed to download or modify it.
                This may be done only with written consent from us.
              </ParagraphMedium>
            </Inner>
            <Inner>
              <HeadingMedium style={{ fontFamily: "secular" }}>
                User Account
              </HeadingMedium>
            </Inner>
            <Inner>
              <ParagraphMedium
                style={{
                  margin: "0px",
                  textAlign: "justify",
                  fontFamily: "secular",
                }}
              >
                If you are an owner of an account on this website, you are
                solely responsible for maintaining the confidentiality of your
                private user details (username and password). You are
                responsible for all activities that occur under your account or
                password. We reserve all rights to terminate accounts, edit or
                remove content and cancel orders in their sole discretion.
              </ParagraphMedium>
            </Inner>
          </Cell>
        </Grid>
      </Outer>
    </>
  );
};

const Outer: React.FunctionComponent<LandingPageProps> = ({ children }) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        background: "peachpuff",
        height: `${window.innerHeight * 2}px`,
        fontFamily: "secular",
      })}
    >
      {children}
    </div>
  );
};
const Inner: React.FunctionComponent<LandingPageProps> = ({
  children,
  gapTop,
}) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        //   background: theme.colors.accent200,
        color: theme.colors.accent700,
        padding: ".25rem",
        marginTop: `${gapTop ? gapTop : 0}px`,
        textAlign: "center",
        fontFamily: "secular",
      })}
    >
      {children}
    </div>
  );
};
