import { FunctionComponent, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationItem as NavigationItem,
  StyledNavigationList as NavigationList,
} from "baseui/header-navigation";
import { Button } from "baseui/button";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../App";

export const Header: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showBackToDashboardButton, setShowBackToDashboardButton] =
    useState(false);

  const landpageRoutes = ["/", "/terms", "/privacy"];

  const auth = useAuth();

  const handleLogin = () => {
    navigate("/login");
  };
  const handleRegister = () => {
    navigate("/register");
  };

  const handleNavigateToDashboard = () => {
    navigate("/app");
  };

  const handleLandpageNavBar = () => {
    if (!auth.user.accessToken) {
      return setShowBackToDashboardButton(false);
    }
    location.pathname !== "/app"
      ? setShowBackToDashboardButton(true)
      : setShowBackToDashboardButton(false);
  };

  useEffect(() => {
    if (landpageRoutes.includes(location.pathname)) {
      handleLandpageNavBar();
    }
  }, [location]);

  const handleLogout = () => {
    auth.signout();
  };
  return (
    <HeaderNavigation
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            backgroundColor: "blueviolet",
          }),
        },
      }}
    >
      <NavigationList $align={ALIGN.left}>
        <NavigationItem style={{ fontWeight: "700" }}>
          <Link style={{ textDecoration: "none" }} to={"/"}>
            <span style={{ color: "white", fontFamily: "zendots" }}>
              M | Midalia
            </span>
          </Link>
        </NavigationItem>
      </NavigationList>
      <NavigationList $align={ALIGN.center} />
      {/* <NavigationList
        $align={ALIGN.right}
        style={{
          padding: "0px",
          marginRight: "10px",
        }}
      >
        {!auth.user.accessToken && (
          <>
            <NavigationItem>
              <Button onClick={handleLogin} disabled={isMobile}>
                Login
              </Button>
            </NavigationItem>
            <NavigationItem>
              <Button onClick={handleRegister} disabled={isMobile}>
                Register
              </Button>
            </NavigationItem>
          </>
        )}
        {auth.user.accessToken && (
          <>
            <NavigationItem>
              Welcome, {auth.user.name ? auth.user.name : "User!"}
            </NavigationItem>
            {showBackToDashboardButton && (
              <NavigationItem>
                <Button onClick={handleNavigateToDashboard}>Dashboard</Button>
              </NavigationItem>
            )}
            <NavigationItem>
              <Button onClick={handleLogout}>Logout</Button>
            </NavigationItem>
          </>
        )}
      </NavigationList> */}
    </HeaderNavigation>
  );
};
