import React, { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationItem as NavigationItem,
  StyledNavigationList as NavigationList,
} from "baseui/header-navigation";
import { useNavigate, Link } from "react-router-dom";

interface FooterProps {
  copyright: string;
}

export const Footer: FunctionComponent<FooterProps> = ({ copyright }) => {
  let navigate = useNavigate();

  return (
    <div>
      <HeaderNavigation
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              bottom: "0px",
              position: "fixed",
              width: "100%",
              height: "50px",
              backgroundColor: "blueviolet",
            }),
          },
        }}
      >
        <NavigationList $align={ALIGN.center}>
          <NavigationItem style={{ color: "white" }}>
            {copyright}
          </NavigationItem>
          {!isMobile && (
            <>
              <NavigationItem>
                <Link style={{ textDecoration: "none" }} to={"/terms"}>
                  <span style={{ color: "white" }}>Terms of service</span>
                </Link>
              </NavigationItem>
              <NavigationItem>
                <Link style={{ textDecoration: "none" }} to={"/privacy"}>
                  <span style={{ color: "white" }}>Privacy Policy</span>
                </Link>
              </NavigationItem>
            </>
          )}
        </NavigationList>
      </HeaderNavigation>
    </div>
  );
};
