import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from '../config';

// Provider hook that creates auth object and handles state
export const useProvideAuth = () => {
  const navigate = useNavigate();

  const logoutUserState = {
    accessToken: undefined,
    email: undefined,
    name: undefined,
    id: undefined,
  };

  const [user, setUser] = useState(logoutUserState);
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (email: string, password: string) => {
    try {
      const payload = { email, password };
      const loginData = await axios.post(
        `${API_URL}/login`,
        payload
      );
      setUser(loginData.data.user);
      if (loginData.data?.user?.accessToken && loginData.data?.user?.isEmailVerified) {
        navigate("/app");
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  };

  const signout = () => {
    setUser(logoutUserState);
  };

  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
  };
};
